import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { collection, doc, query, where } from 'firebase/firestore';
import { auth, firestore } from '../firebase/firebase';
import { constructUserQuestionsWithAnswers } from '../utils/dataHelpers';
import { useAuthState } from 'react-firebase-hooks/auth';

export const useUserData = (userArg) => {
  const [authUser] = useAuthState(auth);
  const user = userArg || authUser;

  const [userDataByUid, loadingUserDataByUid] = useDocument(
    doc(firestore, 'users', user?.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // Some users are stored in the Firebase collection by their email address
  const [userDataByEmail, loadingUserDataByEmail] = useDocument(
    doc(firestore, 'users', user?.email),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const userData = userDataByUid?.data() || userDataByEmail?.data();

  const emailRepliesQuery = query(
    collection(firestore, 'email-replies'),
    where('sender', '==', user?.email)
  );

  const [emailReplies, emailRepliesLoading] = useCollection(emailRepliesQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const [questions, questionsLoading] = useCollection(
    collection(firestore, 'questions'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const userQuestionsWithAnswers = constructUserQuestionsWithAnswers({
    questions,
    answers: emailReplies,
    userEmail: user?.email,
    language: userData?.language,
    bookQaPhotos: userData?.book_qa_photos,
  });

  return {
    userData,
    userQuestionsWithAnswers,
    emailRepliesLoading,
    loading:
      emailRepliesLoading ||
      questionsLoading ||
      loadingUserDataByUid ||
      loadingUserDataByEmail,
  };
};
