export const generateUniqueId = () => {
  const timestamp = Date.now();
  const randomNum = Math.floor(Math.random() * 1000);

  return (timestamp + randomNum).toString(36);
};

export const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  return date.toLocaleDateString('en-GB', options);
};

export const getIpAddress = async () => {
  const response = await fetch('https://geolocation-db.com/json/');
  const data = await response.json();
  return data.IPv4;
};
