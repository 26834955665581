// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { updateDoc, doc, setDoc } from 'firebase/firestore';
import { getIpAddress } from '../utils/common';

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDmHNtVBdR6PM1MpVGf53PArS_tihcd_bM',
  authDomain: 'memowrite-efa1b.firebaseapp.com',
  databaseURL: 'https://memowrite-efa1b-default-rtdb.firebaseio.com',
  projectId: 'memowrite-efa1b',
  storageBucket: 'memowrite-efa1b.appspot.com',
  messagingSenderId: '244790699741',
  appId: '1:244790699741:web:32909de4f1eac8164e1d34',
  measurementId: 'G-31JTNSVWZS',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

// const analytics = getAnalytics(app);

export const updateUser = async ({ uid, data, email }) => {
  try {
    let userRef = doc(firestore, 'users', uid);
    const userRecord = await getDoc(userRef);

    const ip = await getIpAddress();
    const last_visit_date = Math.round(Date.now() / 1000);

    if (!userRecord.exists() && email) {
      const userRefByEmail = doc(firestore, 'users', email);
      const userRecordByEmail = await getDoc(userRefByEmail);

      if (userRecordByEmail.exists()) {
        userRef = userRefByEmail;
      }
    }

    await updateDoc(userRef, { ...data, ip, last_visit_date });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

export const addUser = async ({ uid, data }) => {
  try {
    const userRef = doc(firestore, 'users', uid);

    const ip = await getIpAddress();
    const last_visit_date = Math.round(Date.now() / 1000);

    await setDoc(userRef, { ...data, ip, last_visit_date }, { merge: true });
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};
